import React from "react"
import { graphql } from "gatsby"

import LayoutMaster from "../components/layout"
import SEO from "../components/seo"
import {Section} from "../components/sections"
import section from "../styles/sections.module.css"
import flex from "../styles/flexbox.module.css"

function NotFoundPage({data, location}) {
    const siteTitle = data.site.siteMetadata.title

    return (
      <LayoutMaster location={location} title={siteTitle}>
        <Section className={section.light} flex={`${flex.container} ${flex.justifySpaceAround} ${flex.spacing} ${flex.alignCenter} ${flex.directionColumn}`}>
          <SEO title="404: Not Found" />
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Section>
      </LayoutMaster>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
